export const quienesSomosObject = {
    'nombre': 'Secretaría de Hacienda',
    'tipo': '3',
    'articulo': [
        {
            'titulo': 'Misión',
            'descripcion': 'Administrar la Hacienda Pública Estatal mediante el diseño de políticas fiscales, la asignación prioritaria del presupuesto de egresos, la vigilancia y evaluación del ejercicio del gasto público, con la finalidad de consolidar un sector financiero moderno, eficiente y competitivo, con acciones de gobierno que fortalezcan el bienestar de las y los hidalguenses. ',
            'data': [
                {
                    'titulolink': '',
                    'url': '',
                    'docs': [
                        {
                            'subtitulolink': '',
                            'url': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Visión',
            'descripcion': 'Administrar la Hacienda Pública Estatal mediante el diseño de políticas fiscales, la asignación prioritaria del presupuesto de egresos, la vigilancia y evaluación del ejercicio del gasto. ',
            'data': [
                {
                    'titulolink': '',
                    'url': '',
                    'docs': [
                        {
                            'subtitulolink': '',
                            'url': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Objetivo',
            'descripcion': 'Integrar nuevas tecnologías en la automatización de procesos transversales de la Secretaría de Hacienda, que como ente globalizador ofrece a pendencias, Municipios y Organismos Descentralizados brindando mayor transparencia, controles de ciberseguridad y tableros de control de información fiscal y hacenda ria.',
            'data': [
                {
                    'titulolink': '',
                    'url': '',
                    'docs': [
                        {
                            'subtitulolink': '',
                            'url': ''
                        }
                    ]
                }
            ]
        }
    ]
}
