import { React, useState } from 'react';
import './contenido.css';
import { servidorArray } from '../../json/servidor';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

const Contenido = ({ object }) => {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    setShow(true);
  }

  const closeModal = () => {
    setShow(false); 
    setSelectedImage(null);
  }

  var servers = JSON.parse(JSON.stringify(servidorArray));

  const changeServer = (n) => {
    console.log(n);
    switch (n) {
      case '1':
        return servers.servidoruno;
      case '2':
        return servers.servidordos;
      case '3':
        return servers.servidortres;
      default:
        return []
    }
  }

  return (
    <>
      {object && (
        <div>
          {object.articulo.map((article, j) => (
            <div className='bg-white py-3 px-3 mb-5' key={j}>
              {article?.titulo && <h4 className='fs-2 fw-medium pb-4'>{article.titulo}</h4>}
              <p className='text-justify lh-lg ps-3 pt-3'>{article.descripcion}
                <>
                  {article.data.map((titlink, k) => (
                    <li className='list-group-item list-unstyled py-3 border-0' key={k}>
                      {titlink?.titulolink && (
                        <>
                          <a href={titlink.url} target="_blank" rel="noopener noreferrer">{titlink.titulolink}</a>
                        </>
                      )}
                      <div>
                        {titlink.docs.map((subtit, s) => (
                          <li className='list-unstyled' key={s}>
                            <span>
                              <small>
                                <i className={subtit.icono} style={{ width: '1px', height: '1px', color: 'var(--wine)' }}></i>
                              </small>
                              <a className='ps-3' href={subtit.url} target="_blank" rel="noopener noreferrer">{subtit.subtitulolink}</a>
                            </span>
                            <div className="row">
                              {subtit.images?.map((ima, i) => (
                                <div className='col-md-4 col-xs-12 pt-1' key={i}>
                                  <a onClick={() => handleClick(ima.img)} type="button">
                                    <Image className="img-fluid shadow-1-strong rounded" src={ima.img} />
                                  </a>
                                </div>
                              ))}
                            </div>
                          </li>
                        ))}
                      </div>
                    </li>
                  ))}
                </>
              </p>
            </div>
          ))}
        </div>
      )}

      {/* Modal */}
      <Modal show={show} onHide={closeModal} centered>
        <Modal.Body className='modal-content'>
          {selectedImage && <Image className="img-fluid shadow-1-strong rounded" src={selectedImage} />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Contenido;