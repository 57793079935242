export const registroProyectosInvArray = [
    {
        'titulo': 'Ejercicio Fiscal 2024',
        'data': [
            {
                'nombre': '2024HG230486001 Fortalecimiento al sistema de videovigilancia (Actualización)',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2024/2024HG230486001 Fortalecimiento al sistema de videovigilancia (Actualizacion).pdf'
            },
            // {
            //     'nombre': '2023HG150248005 Modernización del Parque David Ben Gurión',
            //     'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2024/2023HG150248005%20Modernizacion%20del%20Parque%20David%20Ben%20Gurion.pdf'
            // },
            // {
            //     'nombre': '2023HG230486006 Fortalecimiento del Sistema de Video Vigilancia para el Estado de Hidalgo',
            //     'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2024/2023HG230486006%20Fortalecimiento%20del%20Sistema%20de%20Video%20Vigilancia%20para%20el%20Estado%20de%20Hidalgo.pdf'
            // }
        ]   
    },
    {
        'titulo': 'Ejercicio Fiscal 2023',
        'data': [
            {
                'nombre': '2023HG290486001 Adquisición de maquinaria asociada a los programas de la Dirección General de Conservación de Carreteras Estatales y del Centro Estatal de Maquinaria para el Desarrollo, Organismo Descentralizado de la Administración Pública del Estado y sectorizado a la Secretaría de la Secretaría de Infraestructura Pública y Desarrollo Urbano Sostenible',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2023/2023HG290486001%20Adquisicion%20de%20maquinaria.pdf'
            },
            {
                'nombre': '2023HG150251002 Reconstrucción de la Autopista Turística de la Montaña',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2023/2023HG150251002%20Reconstruccion%20de%20la%20Autopista%20Turistica.pdf'
            },
            {
                'nombre': '2023HG150277003 Construcción del Distribuidor Vial Entronque Carretera Federal Tuxpan – Jaltepec',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2023/2023HG150277003%20%20Construccion%20del%20Distribuidor%20Vial.pdf'
            },
            {
                'nombre': '2023HG150288004 Carretera Pachuca - Huejutla, tramo Cerro Colorado - Zacualtipán',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2023/2023HG150288004%20carretera%20Pachuca-Huejutla.pdf'
            },
            {
                'nombre': '2023HG150248005 Modernización del Parque David Ben Gurión',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2023/2023HG150248005%20Modernizacion%20del%20Parque%20David%20Ben%20Gurion.pdf'
            },
            {
                'nombre': '2023HG230486006 Fortalecimiento del Sistema de Video Vigilancia para el Estado de Hidalgo',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2023/2023HG230486006%20Fortalecimiento%20del%20Sistema%20de%20Video%20Vigilancia%20para%20el%20Estado%20de%20Hidalgo.pdf'
            }
        ]   
    },
    {
        'titulo': 'Ejercicio Fiscal 2022',
        'data': [
            {
                'nombre': '2022HG500452001 Planta Piloto CGMP para la Producción de Vacunas y Medicamentos Biotecnologicos',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2022/version%20publica%20ACE%20CITNOVA%20m1.pdf'
            },
            {
                'nombre': '2019HG150948002 Construcción del Distribuidor Vial ubicado en Blvd. Felipe Ángeles y Blvd. Luis Donaldo Colosio (Puente Atirantado Actualización)',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2022/version%20publica%20ACB%20Atirantado%20(Actualizacion).pdf'
            }
        ]   
    },
    {
        'titulo': 'Ejercicio Fiscal 2021',
        'data': [
            {
                'nombre': '2021HG150952001 Sustitución y ampliación del Hospital General de Pachuca',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2021/version%20publica%20hospital.pdf'
            },
            {
                'nombre': '2021HG150852002 Construcción de la Ciudad Trabajo',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/2021/Evaluacion%20ciudad%20trabajo.pdf'
            }
        ]   
    },
    {
        'titulo': 'Ejercicio Fiscal 2020',
        'data': [
            {
                'texto': 'En el Presupuesto de Egresos del Estado de Hidalgo, para el ejercicio fiscal 2020, no se autorizaron proyectos de conformidad con el Art. 13 de la Ley de Disciplina Financiera de las Entidades Federativas y los Municipios, referente a los proyectos de inversión cuyo monto rebase los 10 millones de UDIS.'
            }
        ]   
    },
    {
        'titulo': 'Ejercicio Fiscal 2019',
        'data': [
            {
                'nombre': '2019HG150908001 Construcción de plataforma para cuadro de maniobras, líneas de transmisión 230 KV, cuadro de maniobras 230kv y subestación COFOIN 23 KV y relocalización de líneas de media tensión en Acopinalco.',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/Linea%20de%20media%20tension%20Acopinalco.pdf'
            },
            {
                'nombre': '2019HG150948002 Construcción del distribuidor vial ubicado en Blvd.Felipe Ángeles y Blvd. Luis Donaldo Colosio (Puente Atirantado).',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/Distribuidor%20vial%20Atirantado.pdf'
            },
            {
                'nombre': '2019HG150948003 Distribuidor múltiple Galerías.',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/Distribuidor%20multiple%20Galerias.pdf'
            },
            {
                'nombre': '2019HG150948004 Construcción del distribuidor vial Venta Prieta (km 85+800).',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/Construccion%20de%20distribuidor%20Vial%20Venta%20Prieta.pdf'
            },
            {
                'nombre': '2019HG150948005 Construcción de distribuidor vial en el entronque del Boulevard Luis D. Colosio y el Boulevard Ramón G. Bonfil, segunda etapa.',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/Distribuidor%20vial%20Entronque%20Blvd%20G%20Bonfil.pdf'
            },
            {
                'nombre': '2019HG150800006 Pavimentación y modernización del Boulevard Luis Donaldo Colosio.',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/Pavimentacion%20y%20modernizacion%20del%20Blvd%20Colosio.pdf'
            },
            {
                'nombre': '2019HG150951007 Construcción de solución vial en el Boulevard Luis Donaldo Colosio en el tramo Av. Universidad (CEUNI)-C.F. Pachuca-Tulancingo.',
                'url': '../files/inversiones/pdf/RegistrodeProyectosdeInversion/Construccion%20de%20solucion%20vial%2011%20de%20julio%20y%20CEUNI%20V2.pdf'
            }
        ]   
    },
    {
        'titulo': 'Ejercicio Fiscal 2018',
        'data': [
            {
                'texto': 'En el Presupuesto de Egresos del Estado de Hidalgo, para el ejercicio fiscal 2018, no se autorizaron proyectos de conformidad con el Art. 13 de la Ley de Disciplina Financiera de las Entidades Federativas y los Municipios, referente a los proyectos de inversión cuyo, monto rebase los 1o millones de UDIS. Análisis Costo-Beneficio para programas o proyectos de inversión mayores a 10 millones de UDIS.'
            }
        ]   
    },
    {
        'titulo': 'Ejercicio Fiscal 2017',
        'data': [
            {
                'texto': 'En el Presupuesto de Egresos del Estado de Hidalgo, para el ejercicio fiscal 2017, no se autorizaron proyectos de conformidad con el Art. 13 de la Ley de Disciplina Financiera de las Entidades Federativas y los Municipios, referente a los proyectos de inversión cuyo, monto rebase los 1o millones de UDIS. Análisis Costo-Beneficio para programas o proyectos de inversión mayores a 10 millones de UDIS.'
            }
        ]   
    }
]