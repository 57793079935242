export const rubros =[
	{
		//"url":"Transparencia.html?ver=Normatividad",
	    "icono":"Normatividad.png",
	    "nombre":"1.Normatividad",
	    "formatos":[
	    	{
	    		"nombre":"Marco Normativo",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f01 SH Estatal.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f01 SH Estatal.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Estructura",
	    "icono":"Estructura.png",
	    "nombre":"2.Estructura",
	    "formatos":[
	    	{
	    		"nombre":"Estructura Orgánica",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f02_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f02_a SH.htm",
	    	},
	    	{
	    		"nombre":"Organigrama",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f02_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f02_b SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Facultades",
	    "icono":"Facultades.png",
	    "nombre":"3.Facultades",
	    "formatos":[
	    	{
	    		"nombre":"Facultades",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f03 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f03 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Programas_Operativos_Anuales",
	    "icono":"ProgramasOperativosAnuales.png",
	    "nombre":"4.Programas Operativos Anuales",
	    "formatos":[
	    	{
	    		"nombre":"Metas y Objetivos",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Primer%20Trimestre/a69_f04 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Primer%20Trimestre/a69_f04 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Indicadores_de_Interes",
	    "icono":"IndicadoresInteres.png",
	    "nombre":"5.Indicadores de Interés",
	    "formatos":[
	    	{
	    		"nombre":"Indicadores de Interés",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f5 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f5 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Matriz_de_Indicadores",
	    "icono":"MatrizIndicadores.png",
	    "nombre":"6.Matriz de Indicadores",
	    "formatos":[
	    	{
	    		"nombre":"Matriz de Indicadores",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f06 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f06 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Directorio",
	    "icono":"Directorio.png",
	    "nombre":"7.Directorio",
	    "formatos":[
	    	{
	    		"nombre":"Directorio",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f7 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f7 SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/8.%20Sueldos",
		//"url":"Transparencia.html?ver=Sueldos",
	    "icono":"Sueldos.png",
	    "nombre":"8.Sueldos",
	    "formatos":[
	    	{
	    		"nombre":"Remuneraciones",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f8OM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f8OM.htm",
	    	},
			{
	    		"nombre":"Tabulador",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f8_bOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f8_bOM.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Viaticos",
	    "icono":"Viaticos.png",
	    "nombre":"9.Viáticos",
	    "formatos":[
	    	{
	    		"nombre":"Viáticos",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f9 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f9 SH.htm",
	    	}
	    ]
	},
	{
		// "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/10.%20Plazas",
		//"url":"Transparencia.html?ver=Plazas",
	    "icono":"Plazas.png",
	    "nombre":"10.Plazas",
	    "formatos":[
	    	{
	    		"nombre":"Total de Personal de Base y Confianza",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f10_aOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f10_aOM.htm",
	    	},
	    	{
	    		"nombre":"Total de las Plazas de Base y Confianza",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f10_bOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f10_bOM.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Honorarios",
	    "icono":"Honorarios.png",
	    "nombre":"11.Honorarios",
	    "formatos":[
	    	{
	    		"nombre":"Personal de Honorarios",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f11 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f11 SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/12.%20Declaraciones%20P%C3%Bablicas",
		//"url":"Transparencia.html?ver=Declaraciones_Publicas",
	    "icono":"DeclaracionesPublicas.png",
	    "nombre":"12.Declaraciones Públicas",
	    "formatos":[
	    	{
	    		"nombre":"Declaraciones Patrimoniales",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f12_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f12_SC.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/13.%20Datos%20de%20Contacto%20de%20la%20Unidad",
		//"url":"Transparencia.html?ver=Datos_de_Contacto_de_la_Unidad",
	    "icono":"DatosContactoUnidad.png",
	    "nombre":"13.Datos de contacto de la Unidad",
	    "formatos":[
	    	{
	    		"nombre":"Domicilio de la Unidad de Transparencia",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f13_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f13_SC.htm",
	    	}
	    ]
	},
	{
		"url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/14.%20Convocatorias",
		//"url":"Transparencia.html?ver=Convocatorias",
	    "icono":"Convocatorias.png",
	    "nombre":"14.Convocatorias",
	    "formatos":[
	    	{
	    		"nombre":"Convocatorias a Concursos para Cargos Públicos",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f14OM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f14OM.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Programas_Sociales",
	    "icono":"ProgramasSociales.png",
	    "nombre":"15.Programas Sociales",
	    "formatos":[
	    	{
	    		"nombre":"Programas de Subsidio, Estímulo y Apoyo",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f15_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f15_a SH.htm",
	    	},
	    	{
	    		"nombre":"Padrón de beneficiarios",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f15_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f15_b SH.htm",
	    	}
	    ]
	},
	{
		// "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/16.%20Relaciones%20Laborales",
		//"url":"Transparencia.html?ver=Relaciones_Laborales",
	    "icono":"RelacionesLaborales.png",
	    "nombre":"16.Relaciones Laborales",
	    "formatos":[
	    	{
	    		"nombre":"Condiciones Generales de Trabajo",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f16_aOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f16_aOM.htm",
	    	},
	    	{
	    		"nombre":"Recursos Públicos",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f16_bOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f16_bOM.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Informacion_Curricular",
	    "icono":"InformacionCurricular.png",
	    "nombre":"17.Informacion Curricular",
	    "formatos":[
	    	{
	    		"nombre":"Información Curricular",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f17 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f17 SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/18.%20Sanciones%20Administrativas",
		//"url":"Transparencia.html?ver=Sanciones_Administrativas",
	    "icono":"SancionesAdministrativas.png",
	    "nombre":"18.Sanciones Administrativas",
	    "formatos":[
	    	{
	    		"nombre":"Servidores Publicos Sancionados",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f18_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f18_SC.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Servicios",
	    "icono":"Servicios.png",
	    "nombre":"19.Servicios",
	    "formatos":[
	    	{
	    		"nombre":"Servicios",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f19 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f19 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Tramites",
	    "icono":"Tramites.png",
	    "nombre":"20.Trámites",
	    "formatos":[
	    	{
	    		"nombre":"Trámites",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f20 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f20 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"transparencia/48rubros/Presupuesto_e_Informes_Semestrales.html",
		//"url":"Transparencia.html?ver=Presupuesto_e_Informes_Semestrales",
	    "icono":"PresupuestoInformesTrimestrales.png",
	    "nombre":"21.Presupuesto e Informes Trimestrales",
	    "formatos":[
	    	{
	    		"nombre":"Presupuesto Anual",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Primer%20Trimestre/a69_f21_a%20SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Primer%20Trimestre/a69_f21_a%20SH.htm",
	    	},
	    	{
	    		"nombre":"Gasto trimestral",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f21_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f21_b SH.htm",
	    	},
	    	{
	    		"nombre":"Cuenta Pública",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Primer%20Trimestre/a69_f21_c SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Primer%20Trimestre/a69_f21_c SH.htm",
	    	}
	    ]
	},
	{
		//"url":"transparencia/48rubros/Deuda_Publica.html",
		//"url":"Transparencia.html?ver=Deuda_Publica",
	    "icono":"DeudaPublica.png",
	    "nombre":"22.Deuda Pública",
	    "formatos":[
	    	{
	    		"nombre":"Deuda Pública",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f22 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f22 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Comunicacion_Social_y_Publicidad",
	    "icono":"ComunicacionSocialPublicidad.png",
	    "nombre":"23.Comunicación Social y Publicidad",
	    "formatos":[
	    	{
	    		"nombre":"Programa Anual de Comunicación Social",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Primer%20Trimestre/a69_f23_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Primer%20Trimestre/a69_f23_a SH.htm",
	    	},
	    	{
	    		"nombre":"Erogación de Recursos de Comunicación Social",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f23_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f23_b SH.htm",
	    	},
	    	{
	    		"nombre":"Tiempos Oficiales",
	    		"xlsx":"https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/FC/a69_f23_c_RTH.xlsx",
	    		"html":"https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/FC/a69_f23_c_RTH.htm",
	    	}
	    	// {
	    	// 	"nombre":"Información Relacionada a Tiempos Oficiales",
	    	// 	"xlsx":"http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/2023/Transparencia/xlsx/a69_f23_d_RTVH.xlsx",
	    	// 	"html":"https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/RadioTVH/dir1/2023/Transparencia/htm/a69_f23_d_RTVH.htm",
	    	// }
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/24.%20Resultados%20de%20Auditor%C3%Adas",
		//"url":"Transparencia.html?ver=Resultados_de_Auditorias",
	    "icono":"ResultadosAuditorias.png",
	    "nombre":"24.Resultados de Auditorías",
	    "formatos":[
	    	{
	    		"nombre":"Auditorías",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f24_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f24_SC.htm",
	    	}
	    ]
	},
	{
		//"url":"transparencia/48rubros/Dictamenes_de_Estados_Financieros.html",
		//"url":"Transparencia.html?ver=Dictamenes_de_Estados_Financieros",
	    "icono":"DictamenesFinancieros.png",
	    "nombre":"25.Dictámenes de Estados Financieros",
	    "formatos":[
	    	{
	    		"nombre":"Estados Financieros",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f25 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f25 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Asignacion_de_Recursos_Publicos_a_Personas",
	    "icono":"AsignacionRecursosPublicosPersonas.png",
	    "nombre":"26.Asignacion de Recursos Publicos a Personas",
	    "formatos":[
	    	{
	    		"nombre":"Personas a Quienes se asignó Recursos Públicos",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f26 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f26 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Concesiones",
	    "icono":"Concesiones.png",
	    "nombre":"27.Concesiones",
	    "formatos":[
	    	{
	    		"nombre":"Concesiones, Contratos, Convenios, Permisos, Licencias y Autorizaciones",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f27 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f27 SH.htm",
	    	}
	    ]
	},
	{
		// "url": "https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/28.%20Licitaciones%20P%C3%Bablicas",
	    "icono":"LicitacionesPublicas.png",
	    "nombre":"28.Licitaciones Públicas",
	    "formatos":[
	    	{
	    		"nombre":"Resultados de procedimientos de adjudicación directa, licitación pública e invitación restringida",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f28 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f28 SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/29.%20Informes",
		//"url":"Transparencia.html?ver=Informes",
	    "icono":"Informes.png",
	    "nombre":"29.Informes",
	    "formatos":[
	    	{
	    		"nombre":"Informes",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f29_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f29_SC.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Estadisticas_Generales",
	    "icono":"EstadisticasGenerales.png",
	    "nombre":"30.Estadísticas Generadas",
	    "formatos":[
	    	{
	    		"nombre":"Estadísticas",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f30 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f30 SH.htm",
	    	}
	    ]
	},
	{
		//"url":"transparencia/48rubros/Informe_de_Avances_y_Documentos_Financieros.html",
		//"url":"Transparencia.html?ver=Informe_de_Avances_y_Documentos_Financieros",
	    "icono":"InformeAvancesDocumentosFinancieros.png",
	    "nombre":"31.Informe de Avances y Documentos Financieros",
	    "formatos":[
	    	{
	    		"nombre":"Informe de Estados Financieros",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f31_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f31_a SH.htm",
	    	},
	    	{
	    		"nombre":"Informe Contable, Presupuestal y Programático",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f31_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f31_b SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/32.%20Padr%C3%B3n%20de%20Proveedores%20y%20Contratistas",
		//"url":"Transparencia.html?ver=Padron_de_Proveedores_y_Contratistas",
	    "icono":"PadronProveedoresContratistas.png",
	    "nombre":"32.Padrón de Proveedores y Contratistas",
	    "formatos":[
	    	{
	    		"nombre":"Padrón de Proveedores y Contratistas",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f32_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f32_SC.htm",
	    	}
	    ]
	},
	{
		// "url":"Transparencia.html?ver=Convenios_con_Sectores_Social_y_Privados",
	    "icono":"ConveniosSectoresSocialPrivado.png",
	    "nombre":"33.Convenios con Sectores Social y Privados",
	    "formatos":[
	    	{
	    		"nombre":"Convenios de Coordinación y Concertación",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f33 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f33 SH.htm",
	    	}
	    ]
	},
	{
		// "url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/34.%20Inventario%20de%20Bienes",
		//"url":"Transparencia.html?ver=Inventario_de_Bienes",
	    "icono":"InventarioBienes.png",
	    "nombre":"34.Inventario de Bienes",
	    "formatos":[
	    	{
	    		"nombre":"Inventario de Bienes Muebles",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f34_aOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f34_aOM.htm",
	    	},
	    	{
	    		"nombre":"Alta de Bienes Muebles",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f34_bOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f34_bOM.htm",
	    	},
	    	{
	    		"nombre":"Bajas de Bienes Muebles",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f34_cOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f34_cOM.htm",
	    	},
	    	{
	    		"nombre":"Inventario de Bienes Inmuebles",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f34_dOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f34_dOM.htm",
	    	},
	    	{
	    		"nombre":"Alta de Bienes Inmuebles",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f34_eOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f34_eOM.htm",
	    	},
	    	{
	    		"nombre":"Bajas de Bienes Inmuebles",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f34_fOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f34_fOM.htm",
	    	},
	    	{
	    		"nombre":"Donaciones de Bienes Muebles e Inmuebles",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f34_gOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f34_gOM.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Recomendaciones_y_Atencion_en_Materia_de_Derechos_Humanos",
	    "icono":"RecomendacionesAtenciónMateriaDerechosHumanos.png",
	    "nombre":"35.Recomendaciones y Atención en Materia de Derechos Humanos",
	    "formatos":[
	    	{
	    		"nombre":"Recomendaciones de Derechos Humanos Emitidas por la CNDH",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f35_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f35_a SH.htm",
	    	},
	    	{
	    		"nombre":"Recomendaciones de Derechos Humanos Casos Especiales",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f35_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f35_b SH.htm",
	    	},
	    	{
	    		"nombre":"Las Recomendaciones de Derechos Humanos Org. Int. CNDH",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f35_c SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f35_c SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/36.%20Resoluciones%20y%20Laudos%20de%20Juicios",
		//"url":"Transparencia.html?ver=Resoluciones_y_Laudos_de_Juicios",
	    "icono":"ResolucionesLaudosJuicios.png",
	    "nombre":"36.Resoluciones y Laudos de Juicios",
	    "formatos":[
	    	{
	    		"nombre":"Resoluciones y Laudos",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f36_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f36_SC.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Mecanismos_de_Participacion_Ciudadana",
	    "icono":"MecanismosParticipacionCiudadana.png",
	    "nombre":"37.Mecanismos de Participación Ciudadana",
	    "formatos":[
	    	{
	    		"nombre":"Mecanismos de Participación Ciudadana",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f37_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f37_a SH.htm",
	    	},
	    	{
	    		"nombre":"Resultados de los Mecanismos de Participación Ciudadana",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f37_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f37_b SH.htm",
	    	}
	    ]
	},
	{
		// "url":"https://s-salud.hidalgo.gob.mx/Transparencia/RubrosSsh/DetalleRubroSsh/38.%20Programas%20Ofrecidos",
	    "icono":"ProgramasOfrecidos.png",
	    "nombre":"38.Programas Ofrecidos",
	    "formatos":[
	    	{
	    		"nombre":"Programas que Ofrecen",
	    		"xlsx":"https://s-salud.hidalgo.gob.mx/TransparenciaSSH/xlsx/a69_f38_aSSH.xlsx",
	    		"html":"https://s-salud.hidalgo.gob.mx/TransparenciaSSH/htm/a69_f38_aSSH.htm",
	    	},
	    	{
	    		"nombre":"Tramites a los Programas que Ofrecen",
	    		"xlsx":"https://s-salud.hidalgo.gob.mx/TransparenciaSSH/xlsx/a69_f38_bSSH.xlsx",
	    		"html":"https://s-salud.hidalgo.gob.mx/TransparenciaSSH/htm/a69_f38_bSSH.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-contraloria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/39.%20Actas%20y%20Resoluciones%20del%20Comit%C3%A9%20de%20Transparencia",
		//"url":"Transparencia.html?ver=/Actas_y_Resoluciones_del_Comite_de_Transparencia",
	    "icono":"ActasResolucionesComiteTransparencia.png",
	    "nombre":"39.Actas y Resoluciones del Comité de Transparencia",
	    "formatos":[
	    	{
	    		"nombre":"Informe de Sesiones",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f39a_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f39a_SC.htm",
	    	},
	    	{
	    		"nombre":"Informe de Resoluciones",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f39b_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f39b_SC.htm",
	    	},
	    	{
	    		"nombre":"Actas Integrantes del Comité",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f39c_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f39c_SC.htm",
	    	},
	    	{
	    		"nombre":"Calendario de Sesiones del comité",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f39d_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/transparencia/htm/a69_f39d_SC.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Evaluacion_y_Encuestas_a_Programas_Financiados_con_Recursos_Publicos",
	    "icono":"EvaluacionencuestasProgramas.png",
	    "nombre":"40.Evaluacion y Encuestas a Programas Financiados con Recursos Públicos",
	    "formatos":[
	    	{
	    		"nombre":"Evaluaciones",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Primer%20Trimestre/a69_f40 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Primer%20Trimestre/a69_f40 SH.htm",
	    	}
			// ,
	    	// {
	    	// 	"nombre":"Encuestas",
	    	// 	"xlsx":"../../../files/transparencia/xlsx/2023/Primer%20Trimestre/a69_f40_bSFP.xlsx",
	    	// 	"html":"../../../files/transparencia/htm/2023/Primer%20Trimestre/a69_f40_bSFP.htm",
	    	// }
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Estudios_Pagados_con_Recursos_Publicos",
	    "icono":"EstudiosPagadosRecursosPublicos.png",
	    "nombre":"41.Estudios Pagados con Recursos Públicos",
	    "formatos":[
	    	{
	    		"nombre":"Estudios Pagados con Recursos Públicos",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f41 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f41 SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/42.%20Listados%20Jubilados%20y%20Pensionados",
		//"url":"Transparencia.html?ver=Listado_de_Jublidados_y_Pensionados",
	    "icono":"ListadoJubiladosPencionados.png",
	    "nombre":"42.Listado de Jubilados y Pensionados",
	    "formatos":[
	    	{
	    		"nombre":"Jubilados y Pensionados Leyenda",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f42_aOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f42_aOM.htm",
	    	},
	    	{
	    		"nombre":"Jubilados y Pensionados Listado",
	    		"xlsx":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/xlsx/a69_f42_bOM.xlsx",
	    		"html":"https://oficialiamayor.hidalgo.gob.mx/Transparencia/htm/a69_f42_bOM.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Ingresos_Recibidos_por_Cualquier_Concepto",
	    "icono":"IngresosRecibidosCualquierConcepto.png",
	    "nombre":"43.Ingresos Recibidos por Cualquier Concepto",
	    "formatos":[
	    	{
	    		"nombre":"Ingresos Recibidos",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f43_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f43_a SH.htm",
	    	},
	    	{
	    		"nombre":"Responsables de Recibir los Ingresos",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f43_b SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f43_b SH.htm",
	    	}
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Donaciones_Hechas_a_Terceros",
	    "icono":"DonacionesHechasTerceros.png",
	    "nombre":"44.Donaciones Hechas a Terceros",
	    "formatos":[
	    	{
	    		"nombre":"Donaciones en dinero y en especie realizada",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Segundo%20Trimestre/a69_f44 SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Segundo%20Trimestre/a69_f44 SH.htm",
	    	}
	    	// {
	    	// 	"nombre":"Donaciones Hechas a Terceros en Especie",
	    	// 	"xlsx":"../../../files/transparencia/xlsx/2023/Cuarto%20Trimestre/a69_f44_b%20SH.xlsx",
	    	// 	"html":"../../../files/transparencia/htm/2023/Cuarto%20Trimestre/a69_f44_b%20SH.htm",
	    	// }
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Catalogo_Documental_y_Guia_de_Archivos",
	    "icono":"CatalogoDocumentalGuiaArchivos.png",
	    "nombre":"45.Catálogo Documental y Guía de Archivos",
	    "formatos":[
			// {
	    	// 	"nombre":"Catálogo de Disposición y Guía de Archivos",
	    	// 	"xlsx":"../../../files/transparencia/xlsx/2024/Primer%20Trimestre/a69_f45_a SH.xlsx",
	    	// 	"html":"../../../files/transparencia/htm/2024/Primer%20Trimestre/a69_f45_a SH.htm",
	    	// },
	    	{
	    		"nombre":"Inventarios documentales",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f45_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f45_a SH.htm",
	    	},
			{
	    		"nombre":"Índice de expedientes clasificados como reservados",
	    		"xlsx":"https://s-contraloria.hidalgo.gob.mx/transparencia/xls/a69_f45b_SC.xlsx",
	    		"html":"https://s-contraloria.hidalgo.gob.mx/Transparencia/htm/a69_f45b_SC.htm",
	    	},
			{
	    		"nombre":"Cuadro general de clasificación archivística, catálogo de disposición documental",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Primer%20Trimestre/a69_f45_c SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Primer%20Trimestre/a69_f45_c SH.htm",
	    	}
	    ]
	},
	{
		"url":"https://s-turismo.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/46.Actas%20de%20Sesiones,%20Opiniones%20y%20Recomendaciones%20de%20Consejos%20Consultivos",
	    "icono":"ActasSesionesOpinionesRecomendacionesConsejosConsultivos.png",
	    "nombre":"46.Actas de Sesiones, Opiniones y Recomendaciones de Consejos Consultivos",
	    "formatos":[
	    	{
	    		"nombre":"Actas del Consejo Consultivo Ciudadano",
	    		"xlsx":"https://s-turismo.hidalgo.gob.mx/transparencia/xlsx/a69_f46_a_SECTURH.xlsx",
	    		"html":"https://s-turismo.hidalgo.gob.mx/transparencia/htm/a69_f46_a_SECTURH.htm",
	    	},
	    	{
	    		"nombre":"Opiniones del Consejo Consultivo Ciudadano",
	    		"xlsx":"https://s-turismo.hidalgo.gob.mx/transparencia/xlsx/a69_f46_b_SECTURH.xlsx",
	    		"html":"https://s-turismo.hidalgo.gob.mx/transparencia/htm/a69_f46b_SECTURH.htm",
	    	}
	    ]
	},
	{
		// "url":"https://procuraduria.hidalgo.gob.mx/Transparencia/Rubros/DetalleRubro/Solicitud%20de%20Intervecion%20de%20%20Comunicaciones",
		//"url":"Transparencia.html?ver=Solicitud_de_Intervencion_de_Comunicaciones",
	    "icono":"SolicitudIntervencionComunicaciones.png",
	    "nombre":"47.Solicitud de Intervencion de Comunicaciones",
	    "formatos":[
	    	{
	    		"nombre":"Intervenciones de comunicaciones",
	    		"xlsx":"https://procuraduria.hidalgo.gob.mx/Transparencia/xlsx/a69_f47_a_PGJEH.xlsx",
	    		"html":"https://procuraduria.hidalgo.gob.mx/Transparencia/htm/a69_f47_a_PGJEH.htm",
	    	},
	    	{
	    		"nombre":"Localización Geográfica",
	    		"xlsx":"https://procuraduria.hidalgo.gob.mx/Transparencia/xlsx/a69_f47_b_PGJEH.xlsx",
	    		"html":"https://procuraduria.hidalgo.gob.mx/Transparencia/htm/a69_f47_b_PGJEH.htm",
	    	}
	    	// {
	    	// 	"nombre":"Mensaje de Solicitudes a empresas para la intervención de comunicaciones privadas",
	    	// 	"xlsx":"https://procuraduria.hidalgo.gob.mx/Transparencia/xlsx/a69_f47_c_PGJEH.xlsx",
	    	// 	"html":"https://procuraduria.hidalgo.gob.mx/Transparencia/htm/a69_f47_c_PGJEH.htm",
	    	// }
	    ]
	},
	{
		//"url":"Transparencia.html?ver=Informacion_de_Utilidad_o_Relevante",
	    "icono":"InformacionUtilidadRelevante.png",
	    "nombre":"48.Informacion de Utilidad o Relevante",
	    "formatos":[
	    	{
	    		"nombre":"Interés Público",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f48_a SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f48_a SH.htm",
	    	},
	    	{
	    		"nombre":"Preguntas Frecuentes",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f48_b.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f48_b.htm",
	    	},
	    	{
	    		"nombre":"Transparencia Proactiva",
	    		"xlsx":"../../../files/transparencia/xlsx/2024/Tercer Trimestre/a69_f48_c SH.xlsx",
	    		"html":"../../../files/transparencia/htm/2024/Tercer Trimestre/a69_f48_c SH.htm",
	    	}
	    ]
	}
]